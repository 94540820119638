import React, { useEffect, useState } from "react";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import PropTypes from "prop-types";

//import images
import Hospital from "../../assets/images/hospital.svg";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { changeUserAccess } from "../../store/actions";

const WebAppsDropdown = ({ centers, centerAccess }) => {
  const dispatch = useDispatch();
  const [access, setAccess] = useState(centerAccess);
  const [isWebAppDropdown, setIsWebAppDropdown] = useState(false);
  const [ctrlCmdPressed, setCtrlCmdPressed] = useState(false);
  const toggleWebAppDropdown = () => {
    setIsWebAppDropdown(!isWebAppDropdown);
  };

  const changeAccess = (centerId) => {
    let updateAccess = [...access];
    const checkCenter = access.includes(centerId);
    if (checkCenter) {
      updateAccess = updateAccess.filter((id) => id !== centerId);
    } else {
      updateAccess = [centerId, ...access];
    }
    setAccess(updateAccess);
  };

  // Event handler function for key down event
  const handleKeyDown = (event) => {
    // Check if Ctrl or Cmd key is pressed
    if (event.key === "Control" || event.key === "Meta") {
      setCtrlCmdPressed(true);
    }
  };
  // Event handler function for key up event
  const handleKeyUp = (event) => {
    // Check if Ctrl or Cmd key is released
    if (event.key === "Control" || event.key === "Meta") {
      setCtrlCmdPressed(false);
    }
  };

  // Effect hook to add event listeners when the component mounts
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (!ctrlCmdPressed) dispatch(changeUserAccess(access));
  }, [dispatch, access, ctrlCmdPressed]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isWebAppDropdown}
        toggle={toggleWebAppDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          tag="button"
          type="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-category-alt text-white fs-22"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 fw-semibold fs-15"> Centers </h6>
              </Col>
              <div className="col-auto">
                <Link to="/centers" className="btn btn-sm btn-soft-info">
                  {" "}
                  View All Center
                  <i className="ri-arrow-right-s-line align-middle"></i>
                </Link>
              </div>
            </Row>
          </div>

          <div className="p-2 overflow-auto" style={{ height: "180px" }}>
            <Row className="row-gap-3">
              {(centers || []).map((center) => (
                <Col key={center._id} xs={4}>
                  <div className="form-check card-radio">
                    <input
                      id={center.title + center._id}
                      name="data-layout"
                      type="checkbox"
                      value={center["_id"]}
                      checked={access.includes(center["_id"])}
                      onChange={() => {
                        changeAccess(center["_id"]);
                      }}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label me-0 d-flex flex-column align-items-center justify-content-center text-center p-0 avatar-md"
                      htmlFor={center.title + center._id}
                    >
                      <img className="avatar-sm" src={Hospital} alt="Rehab" />
                      <span className="fs-10">{center["title"] || ""}</span>
                    </label>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

WebAppsDropdown.prototype = {
  centers: PropTypes.array,
  centerAccess: PropTypes.array,
};

const mapStateToProps = (state) => ({
  centers: state.Center.data,
  centerAccess: state.User.centerAccess,
});

export default connect(mapStateToProps)(WebAppsDropdown);
