import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

import Patient from "../pages/Patient";

//login
const Login = React.lazy(() => import("../pages/Authentication/Login"));
// import Login from "../pages/Authentication/Login";
const ForgetPasswordPage = React.lazy(() =>
  import("../pages/Authentication/ForgetPassword")
);
const Logout = React.lazy(() => import("../pages/Authentication/Logout"));
const Register = React.lazy(() => import("../pages/User"));

//setting
const Setting = React.lazy(() => import("../pages/Setting"));

//notification
const Notification = React.lazy(() => import("../pages/Notification"));

//recycle bin
const Recyclebin = React.lazy(() => import("../pages/Recyclebin"));

//not found
const Basic404 = React.lazy(() =>
  import("../pages/AuthenticationInner/Errors/Basic404")
);

// User Profile
const UserProfile = React.lazy(() =>
  import("../pages/Authentication/user-profile")
);

// Center
const Center = React.lazy(() => import("../pages/Center"));

// Patient
// const Patient = React.lazy(() => import("../pages/Patient"));

// Booking
const Booking = React.lazy(() => import("../pages/Booking"));
const Medicine = React.lazy(() => import("../pages/Medicine"));

//Lead
const Lead = React.lazy(() => import("../pages/Lead"));

//Report
const Report = React.lazy(() => import("../pages/Report"));

const allElements = [
  { element: Register, label: "User" },
  { element: Center, label: "Center" },
  { element: Patient, label: "Patient" },
  { element: Booking, label: "Booking" },
  { element: Setting, label: "Setting" },
  { element: Recyclebin, label: "Recycle bin" },
  { element: Lead, label: "Lead" },
  { element: Report, label: "Report" },
];

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardEcommerce },
  { path: "/index", component: DashboardEcommerce },

  //User Profile
  { path: "/profile", component: UserProfile },
  { path: "/user/*", component: Register },
  { path: "/patient/*", component: Patient },
  { path: "/setting/*", component: Setting },
  { path: "/recyclebin/*", component: Recyclebin },
  { path: "/medicine", component: Medicine },
  { path: "/notification", component: Notification },
  // { path: "/Lead", component: Lead },
  //Users

  //Center
  { path: "/centers", component: Center },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },

  //AuthenticationInner pages
  // { path: "/auth-signin-basic", component: BasicSignIn },
  // { path: "/auth-signin-cover", component: CoverSignIn },
  // { path: "/auth-signup-basic", component: BasicSignUp },
  // { path: "/auth-signup-cover", component: CoverSignUp },
  // { path: "/auth-pass-reset-basic", component: BasicPasswReset },
  // { path: "/auth-pass-reset-cover", component: CoverPasswReset },
  // { path: "/auth-lockscreen-basic", component: BasicLockScreen },
  // { path: "/auth-lockscreen-cover", component: CoverLockScreen },
  // { path: "/auth-logout-basic", component: BasicLogout },
  // { path: "/auth-logout-cover", component: CoverLogout },
  // { path: "/auth-success-msg-basic", component: BasicSuccessMsg },
  // { path: "/auth-success-msg-cover", component: CoverSuccessMsg },
  // { path: "/auth-twostep-basic", component: BasicTwosVerify },
  // { path: "/auth-twostep-cover", component: CoverTwosVerify },
  // { path: "/*", component: Basic404 },
  // { path: "/auth-404-cover", component: Cover404 },
  // { path: "/auth-404-alt", component: Alt404 },
  // { path: "/auth-500", component: Error500 },
  // { path: "/pages-maintenance", component: Maintenance },
  // { path: "/pages-coming-soon", component: ComingSoon },

  // { path: "/landing", component: OnePage },
  // { path: "/nft-landing", component: NFTLanding },

  // { path: "/auth-pass-change-basic", component: BasicPasswCreate },
  // { path: "/auth-pass-change-cover", component: CoverPasswCreate },
  // { path: "/auth-offline", component: Offlinepage },
];

export { authProtectedRoutes, publicRoutes, allElements };
