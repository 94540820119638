import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Input,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { CAPSULE, CREAM, DROP } from "../../../Components/constants/medicine";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const Inovice = ({ data, dataList, fieldName, addItem }) => {
  const [searchItem, setSearchItem] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchItem(value);
  };

  return (
    <React.Fragment>
      <div>
        <UncontrolledDropdown
          className="me-2 dropdown-menu-md"
          direction="down"
        >
          <DropdownToggle className="p-0 w-100 position-relative" color="light">
            <Input
              value={searchItem}
              onChange={handleChange}
              size={"sm"}
              className="w-100"
            />
            {/* add custom medicine */}
            <span
              onClick={() => {
                addItem(searchItem, data);
                setSearchItem("");
              }}
              className="link-success ri-send-plane-2-fill dropdown-input-icon"
            ></span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-md overflow-auto dropdown-height-md">
            <DropdownItem></DropdownItem>
            {(dataList || [])
              .filter((item) =>
                item[fieldName]
                  .toLowerCase()
                  .includes(searchItem.toLocaleLowerCase())
              )
              .map((item) => (
                <DropdownItem
                  className={
                    item.quantity === 0
                      ? "d-flex align-items-center fs-6 text-danger"
                      : "d-flex align-items-center link-primary fs-6"
                  }
                  key={item["_id"]}
                  onClick={() => {
                    if (item.quantity === 0) {
                      toast.error("Inovice out of stock", {
                        position: "top-center",
                        autoClose: true,
                      });
                    } else addItem(item, data);
                  }}
                >
                  {item["type"] === CAPSULE ? (
                    <i className="las la-pills text-info me-2 fs-5"></i>
                  ) : item["type"] === DROP ? (
                    <i className="bx bxs-droplet-half text-info me-2 fs-5"></i>
                  ) : item["type"] === CREAM ? (
                    <i className="las la-prescription-bottle-alt text-info me-2 fs-5"></i>
                  ) : null}
                  <span>{item[fieldName]}</span>
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </React.Fragment>
  );
};

Inovice.propTypes = {
  data: PropTypes.array,
  setMedicines: PropTypes.func,
  dataList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  //dataList: state.Inovice.data,
});

export default connect(mapStateToProps)(Inovice);
