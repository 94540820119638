//TYPE
export const CAPSULE = "CAP";
export const CREAM = "CREAM";
export const DROP = "DROP";
export const SYRUP = "SYP";
export const TAB = "TAB";
export const INJ = "INJ";
export const SPRAY = "ORAL SPRAY";
//UNIT
export const MG = "MG";
export const MCG = "MCG";
export const GM = "GM";

export const medicineTypes = [CAPSULE, CREAM, DROP, SYRUP, TAB, INJ, SPRAY];
export const medicineUnits = [GM, MCG, MG];
