import React, { useEffect, useState } from "react";
import { Container, Input } from "reactstrap";
import Placeholder from "../Patient/Views/Components/Placeholder";
import PropTypes from "prop-types";
import {
  fetchAllPatients,
  fetchBillItems,
  fetchBillNotification,
  fetchCenters,
  fetchMedicines,
  fetchPaymentAccounts,
  fetchUserLogs,
  setTotalAmount,
  viewPatient,
} from "../../store/actions";
import { connect, useDispatch } from "react-redux";
import TimelineRight from "../Patient/Views/Components/Timeline/TimelineRight";
import Header from "../Report/Components/Header";
import { endOfDay, startOfDay } from "date-fns";
import { Link } from "react-router-dom";
import PatientFilter from "./PatientFilter";
import { TIMELINE_VIEW } from "../../Components/constants/patient";

const DashboardEcommerce = ({
  user,
  users,
  pageAccess,
  userCenters,
  logs,
  patients,
  loading,
}) => {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [date, setDate] = useState({
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  });
  const access = pageAccess
    ?.find((pg) => pg.name === "Patient")
    ?.subAccess?.find((sub) => sub.name.toUpperCase() === TIMELINE_VIEW);

  console.log(access, "access");

  useEffect(() => {
    dispatch(fetchCenters(user?.centerAccess));
  }, [dispatch, user]);

  useEffect(() => {
    if (!patients.length) dispatch(fetchAllPatients());
    dispatch(fetchMedicines());
    dispatch(fetchBillItems(userCenters));
    dispatch(fetchPaymentAccounts(userCenters));
    dispatch(fetchBillNotification(userCenters));
  }, [dispatch, userCenters, patients]);

  useEffect(() => {
    // if (access)
    dispatch(
      fetchUserLogs({
        ...date,
        centerAccess: JSON.stringify(userCenters),
        users: JSON.stringify(selectedOptions?.length && selectedOptions[0]),
      })
    );
  }, [dispatch, access, date, userCenters, selectedOptions]);

  document.title = "Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid></Container>
        {access && (
          <>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <div className="w-25">
                <PatientFilter
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
              </div>
              <Header reportDate={date} setReportDate={setDate} />
            </div>
            {loading ? (
              <Placeholder />
            ) : (
              <div>
                <div className="timeline">
                  {(logs || []).map((item, idx) => (
                    <React.Fragment key={idx}>
                      {item.patient ? (
                        <Link
                          onClick={() => {
                            dispatch(viewPatient(item.patient));
                            // dispatch(
                            //   setTotalAmount({
                            //     totalPayable: 0,
                            //     totalAdvance: 0,
                            //   })
                            // );
                          }}
                          to={`/patient/${item.patient?._id}`}
                        >
                          <TimelineRight data={item}>
                            <h6 className="display-6 fs-14 text-info">
                              {item.center?.map((cn) => cn?.title).join(", ") ||
                                ""}
                            </h6>
                            <h6 className="display-6 fs-14">
                              {item.patient?.name || ""}
                              <span className="text-success">
                                {` ${item.patient?.id?.prefix || ""}${
                                  item.patient?.id?.value || ""
                                }`}
                              </span>
                            </h6>
                          </TimelineRight>
                        </Link>
                      ) : (
                        <TimelineRight data={item}>
                          <h6 className="display-6 fs-14 text-info">
                            {item.center?.map((cn) => cn?.title).join(", ") ||
                              ""}
                          </h6>
                          <h6 className="display-6 fs-14">
                            {item.patient?.name || ""}
                            <span className="text-success">
                              {` ${item.patient?.id?.prefix || ""}${
                                item.patient?.id?.value || ""
                              }`}
                            </span>
                          </h6>
                        </TimelineRight>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

DashboardEcommerce.prototype = {
  user: PropTypes.object,
  users: PropTypes.array,
  centers: PropTypes.array,
  isFormOpen: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.User.user,
  pageAccess: state.User.user.pageAccess.pages,
  users: state.User.data,
  userCenters: state.User.centerAccess,
  logs: state.Log.user,
  patients: state.Patient.allPatients,
  loading: state.Log.loading,
});

export default connect(mapStateToProps)(DashboardEcommerce);
