import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect, useDispatch } from "react-redux";
import TimelineRight from "./Components/Timeline/TimelineRight";
import TimelineLeft from "./Components/Timeline/TimelineLeft";
import RenderWhen from "../../../Components/Common/RenderWhen";
import TimelineCenter from "./Components/Timeline/TimelineCenter";
import { fetchPatientTimeline } from "../../../store/actions";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
} from "reactstrap";
import { timelineFilters } from "../../../Components/constants/patient";

const PATIENT_CHART = "PATIENT_CHART";
const PATIENT_BILL = "PATIENT_BILL";

const Timeline = ({ timeline, patient }) => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState(timelineFilters.map((_) => _.name));

  useEffect(() => {
    dispatch(fetchPatientTimeline({ patient: patient._id, filter }));
  }, [dispatch, patient, filter]);
  const [dropdown, setDropdown] = useState(false);
  const toggleFilter = () => setDropdown(!dropdown);

  return (
    <React.Fragment>
      <div>
        <div className="py-3 text-end">
          <Dropdown isOpen={dropdown} toggle={() => {}}>
            <DropdownToggle onClick={toggleFilter}>--</DropdownToggle>
            <DropdownMenu>
              {(timelineFilters || []).map((item, idx) => (
                <DropdownItem key={idx} value={item.name}>
                  <div className="d-flex align-items-center">
                    <Input
                      className="mt-0"
                      type="checkbox"
                      onChange={(e) => {
                        const value = e.target.value;
                        const isCurrentlyChecked = filter.includes(value);

                        // Create a new array based on the current state
                        const newFilter = isCurrentlyChecked
                          ? filter.filter((item) => item !== value) // Uncheck, remove the value
                          : [...filter, value]; // Check, add the value

                        setFilter(newFilter);
                      }}
                      checked={filter.includes(item.name)}
                      value={item.name}
                      id={idx + item.name}
                    />
                    <Label
                      className="mb-0 ms-2 w-100"
                      htmlFor={idx + item.name}
                    >
                      {item.label}
                    </Label>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="timeline">
          {(timeline || []).map((item, idx) => (
            <React.Fragment key={idx}>
              <RenderWhen
                isTrue={item.relation === PATIENT_CHART ? true : false}
              >
                <TimelineLeft data={item} />
              </RenderWhen>
              <RenderWhen
                isTrue={item.relation === PATIENT_BILL ? true : false}
              >
                <TimelineRight data={item} />
              </RenderWhen>
              <RenderWhen
                isTrue={
                  item.relation !== PATIENT_BILL &&
                  item.relation !== PATIENT_CHART
                    ? true
                    : false
                }
              >
                <TimelineCenter data={item} />
              </RenderWhen>
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

Timeline.propTypes = {
  timeline: PropTypes.array,
  patient: PropTypes.object,
};

const mapStateToProps = (state) => ({
  timeline: state.Timeline.patient,
  patient: state.Patient.patient,
});

export default connect(mapStateToProps)(Timeline);
