import React, { useEffect } from "react";
import PropTypes from "prop-types";

//components
import PatientTopbar from "./PatientTopbar";
import Views from "./Views";

//redux
import { connect, useDispatch } from "react-redux";
import {
  fetchBillsAddmissions,
  fetchChartsAddmissions,
  resetOpdPatientBills,
  resetOpdPatientCharts,
} from "../../store/actions";
import RenderWhen from "../../Components/Common/RenderWhen";

const Main = ({ patient, deletePatient, setDeletePatient }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    /*
    @todo
    - if patient is not admitted yet then reset its bills, charts & timeline as we know it doesn't have any ipd data.
    - so in order to prevent any extra server load we simply reset its data.
    */
    if (patient) {
      if (patient.addmissions?.length) {
        // dispatch(resetOpdPatientCharts());
        // dispatch(resetOpdPatientBills());
        dispatch(fetchChartsAddmissions(patient.addmissions));
        dispatch(fetchBillsAddmissions(patient.addmissions));
      } else {
        dispatch(resetOpdPatientCharts());
        dispatch(resetOpdPatientBills());
        // dispatch(resetOpdPatientTimeline());
      }
    }
  }, [dispatch, patient]);

  return (
    <React.Fragment>
      <RenderWhen isTrue={patient ? true : false}>
        <div className="w-100">
          <PatientTopbar
            deletePatient={deletePatient}
            setDeletePatient={setDeletePatient}
          />
          <Views />
        </div>
      </RenderWhen>
    </React.Fragment>
  );
};

Main.propTypes = {
  patient: PropTypes.object,
  deletePatient: PropTypes.object,
  setDeletePatient: PropTypes.func,
};

const mapStateToProps = (state) => ({
  patient: state.Patient.patient,
});

export default connect(mapStateToProps)(Main);
