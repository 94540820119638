import React from "react";
import PropTypes from "prop-types";
import {
  INVOICE,
  ADVANCE_PAYMENT,
  REFUND,
  OPD,
  IPD,
} from "../../constants/patient";

//bills
import Invoice from "./Invoice";
import OPDInvoice from "./OPD/Inovice";
import Receipt from "./Receipt";
import RenderWhen from "../../Common/RenderWhen";

const Bills = ({ bill, patient, center, doctor }) => {
  return (
    <React.Fragment>
      <RenderWhen isTrue={bill.type === OPD}>
        <OPDInvoice
          bill={bill}
          patient={patient}
          center={center}
          doctor={doctor}
        />
      </RenderWhen>

      <RenderWhen
        isTrue={
          (bill.bill === INVOICE || bill.bill === REFUND) && bill.type === IPD
        }
      >
        <Invoice bill={bill} patient={patient} center={center} />
      </RenderWhen>

      <RenderWhen isTrue={bill.bill === ADVANCE_PAYMENT && bill.type === IPD}>
        <Receipt bill={bill} patient={patient} center={center} />
      </RenderWhen>
    </React.Fragment>
  );
};

Bills.propTypes = {
  bill: PropTypes.object.isRequired,
};

export default Bills;
