import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//flatpicker
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

import CustomModal from "../../../Components/Common/Modal";

//redux
import { connect, useDispatch } from "react-redux";
import {
  addmitIpdPatient,
  admitDischargePatient,
} from "../../../store/actions";
import { ADMIT_PATIENT } from "../../../Components/constants/patient";

const AdmitPatient = ({ isOpen, patient }) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      patientId: patient?._id,
      addmissionDate: "",
    },
    validationSchema: Yup.object({
      addmissionDate: Yup.date().required("Please select addmission date"),
    }),
    onSubmit: (values) => {
      dispatch(addmitIpdPatient(values));
      validation.resetForm();
      toggle();
    },
  });

  const toggle = () =>
    dispatch(admitDischargePatient({ data: null, isOpen: "" }));

  return (
    <React.Fragment>
      <CustomModal
        isOpen={isOpen === ADMIT_PATIENT}
        title={"Admit Patient"}
        toggle={toggle}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          className="needs-validation"
          action="#"
        >
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="addmissionDate" className="form-label">
                  Addmission Date
                </Label>
                <Flatpicker
                  name="addmissionDate"
                  value={validation.values.addmissionDate || ""}
                  onChange={([e]) => {
                    const event = {
                      target: { name: "addmissionDate", value: e },
                    };
                    validation.handleChange(event);
                  }}
                  options={{
                    dateFormat: "d M, Y",
                    // disable: [
                    //   {
                    //     from: "1900-01-01", // Replace with an appropriate minimum date
                    //     to:
                    //       new Date(patient?.addmission?.dischargeDate) ||
                    //       "1900-01-01", // Use the admission date or today's date
                    //   },
                    // ],
                    enable: [
                      function (date) {
                        return patient?.addmission?.dischargeDate
                          ? date > new Date(patient?.addmission?.dischargeDate)
                          : true;
                      },
                    ],
                    // enable: [
                    //   function (date) {
                    //     return date.getDate() === new Date().getDate();
                    //   },
                    // ],
                  }}
                  onBlur={validation.handleBlur}
                  // onInvalid={
                  //   validation.touched.addmissionDate &&
                  //   validation.errors.addmissionDate
                  //     ? true
                  //     : false
                  // }
                  className="form-control shadow-none bg-light"
                  id="dateOfAdmission"
                />
                {/* <Input
                  name="addmissionDate"
                  className="form-control"
                  placeholder="Select Addmission Date"
                  type="date"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.addmissionDate || ""}
                  invalid={
                    validation.touched.addmissionDate &&
                    validation.errors.addmissionDate
                      ? true
                      : false
                  }
                  autoComplete="on"
                /> */}
                {validation.touched.addmissionDate &&
                validation.errors.addmissionDate ? (
                  <FormFeedback className="d-block" type="invalid">
                    {validation.errors.addmissionDate}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xs={12} className="mt-3">
              <div className="d-flex gap-3 justify-content-end">
                <Button onClick={toggle} size="sm" color="danger" type="button">
                  Cancel
                </Button>
                <Button size="sm" type="submit">
                  Save
                  {/* {chart ? "Update" : "Save"} */}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </CustomModal>
    </React.Fragment>
  );
};

AdmitPatient.propTypes = {
  isOpen: PropTypes.string,
  patient: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isOpen: state.Patient.admitDischargePatient?.isOpen,
  patient: state.Patient.patient,
});

export default connect(mapStateToProps)(AdmitPatient);
