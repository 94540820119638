import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteBillItem,
  deletePaymentAccount,
  editBillItem,
  editCalenderDuration,
  editDoctorSchedule,
  getAllDoctorSchedule,
  getBillItems,
  getCalenderDuration,
  getDoctorSchedule,
  getPaymentAccounts,
  postBillItem,
  postCalenderDuration,
  postDoctorSchedule,
  postPaymentAccount,
} from "../../../helpers/backend_helper";
import { setAlert } from "../alert/alertSlice";

const initialState = {
  invoiceProcedures: [],
  paymentAccounts: [],
  doctorSchedule: [],
  doctor: null,
  appointmentsInRange: [],
  calender: null,
};

/* -------------------------------------------------------------------- */
/* ---------------------------- BILLING ------------------------------- */
/* -------------------------------------------------------------------- */
export const addBillItem = createAsyncThunk(
  "postBillItem",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await postBillItem(data);
      dispatch(
        setAlert({ type: "success", message: "Bill Item Saved Successfully" })
      );
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.message }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const fetchBillItems = createAsyncThunk(
  "getBillItems",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await getBillItems(data);
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.response }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const updateBillItem = createAsyncThunk(
  "editBillItem",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await editBillItem(data);
      dispatch(
        setAlert({
          type: "success",
          message: "Invoice Procedure Updated Successfully",
        })
      );
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.message }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const removeBillItem = createAsyncThunk(
  "deleteBillItem",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteBillItem(data);
      dispatch(
        setAlert({
          type: "success",
          message: "Invoice Procedure Deleted Successfully",
        })
      );
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.message }));
      return rejectWithValue("something went wrong");
    }
  }
);

//ADVANCE PAYMENT
export const addPaymentAccount = createAsyncThunk(
  "postPaymentAccount",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await postPaymentAccount(data);
      dispatch(
        setAlert({
          type: "success",
          message: "Payment Account Saved Successfully",
        })
      );
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.message }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const fetchPaymentAccounts = createAsyncThunk(
  "getPaymentAccounts",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await getPaymentAccounts(data);
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.response }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const removePaymentAccount = createAsyncThunk(
  "deletePaymentAccount",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await deletePaymentAccount(data);
      dispatch(
        setAlert({
          type: "success",
          message: "Payment Account Deleted Successfully",
        })
      );
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.message }));
      return rejectWithValue("something went wrong");
    }
  }
);
/* -------------------------------------------------------------------- */
/* ---------------------------- BILLING ------------------------------- */
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* ---------------------------- DOCTOR SCHEDULE ------------------------------ */
/* -------------------------------------------------------------------- */

export const fetchAllDoctorSchedule = createAsyncThunk(
  "getAllDoctorSchedule",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAllDoctorSchedule(data);
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.response }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const fetchDoctorSchedule = createAsyncThunk(
  "getDoctorSchedule",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await getDoctorSchedule(data);
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.response }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const addDoctorSchedule = createAsyncThunk(
  "postDoctorSchedule",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await postDoctorSchedule(data);
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.response }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const updateDoctorSchedule = createAsyncThunk(
  "updateDoctorSchedule",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await editDoctorSchedule(data);
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.response }));
      return rejectWithValue("something went wrong");
    }
  }
);

/* -------------------------------------------------------------------- */
/* ---------------------------- DOCTOR SCHEDULE ----------------------- */
/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */
/* ---------------------------- CALENDER ------------------------------ */
/* -------------------------------------------------------------------- */

export const fetchCalenderDuration = createAsyncThunk(
  "getCalenderDuration",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCalenderDuration(data);
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.response }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const addCalenderDuration = createAsyncThunk(
  "postCalenderDuration",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await postCalenderDuration(data);
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.response }));
      return rejectWithValue("something went wrong");
    }
  }
);

export const updateCalenderDuration = createAsyncThunk(
  "updateCalenderDuration",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await editCalenderDuration(data);
      return response;
    } catch (error) {
      dispatch(setAlert({ type: "error", message: error.response }));
      return rejectWithValue("something went wrong");
    }
  }
);

/* -------------------------------------------------------------------- */
/* ---------------------------- CALENDER ------------------------------ */
/* -------------------------------------------------------------------- */

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    // postMedicine: (state, { payload }) => {
    //   state.data = [...state.data, payload.payload]
    // },
    setBillItems: (state, { payload }) => {
      state.invoiceProcedures = payload;
    },
    setPaymentAccounts: (state, { payload }) => {
      state.paymentAccounts = payload;
    },
  },
  extraReducers: (builder) => {
    /* -------------------------------------------------------------------- */
    /* ---------------------------- BILLING ------------------------------- */
    /* -------------------------------------------------------------------- */
    builder
      .addCase(addBillItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBillItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.invoiceProcedures = payload.payload;
      })
      .addCase(addBillItem.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(fetchBillItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBillItems.fulfilled, (state, { payload }) => {
        state.loading = false;
        localStorage.setItem("billItems", JSON.stringify(payload.payload));
        state.invoiceProcedures = payload.payload;
      })
      .addCase(fetchBillItems.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(removeBillItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeBillItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.invoiceProcedures = state.invoiceProcedures.filter(
          (item) => item._id !== payload.payload?._id
        );
      })
      .addCase(removeBillItem.rejected, (state, action) => {
        state.loading = false;
      });

    builder
      .addCase(updateBillItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBillItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        const findMedicineIndex = state.invoiceProcedures.findIndex(
          (el) => el._id === payload.payload?._id
        );
        state.invoiceProcedures[findMedicineIndex] = payload.payload;
      })
      .addCase(updateBillItem.rejected, (state, action) => {
        state.loading = false;
      });

    //ADVANCE PAYMENT
    builder
      .addCase(addPaymentAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPaymentAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.paymentAccounts = payload.payload;
      })
      .addCase(addPaymentAccount.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(fetchPaymentAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPaymentAccounts.fulfilled, (state, { payload }) => {
        state.loading = false;
        localStorage.setItem(
          "paymentAccounts",
          JSON.stringify(payload.payload)
        );
        state.paymentAccounts = payload.payload;
      })
      .addCase(fetchPaymentAccounts.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(removePaymentAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(removePaymentAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.paymentAccounts = state.paymentAccounts.filter(
          (item) => item._id !== payload.payload._id
        );
      })
      .addCase(removePaymentAccount.rejected, (state, action) => {
        state.loading = false;
      });
    /* -------------------------------------------------------------------- */
    /* ---------------------------- BILLING ------------------------------- */
    /* -------------------------------------------------------------------- */

    /* -------------------------------------------------------------------- */
    /* ---------------------------- DOCTOR SCHEDULE ----------------------- */
    /* -------------------------------------------------------------------- */

    builder
      .addCase(fetchAllDoctorSchedule.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDoctorSchedule.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.doctorSchedule = payload.payload;
      })
      .addCase(fetchAllDoctorSchedule.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(fetchDoctorSchedule.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDoctorSchedule.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.doctor = payload.payload;
        state.appointmentsInRange = payload.appointmentsInRange;
      })
      .addCase(fetchDoctorSchedule.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(addDoctorSchedule.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDoctorSchedule.fulfilled, (state, { payload }) => {
        state.loading = false;

        const findIndex = state.doctorSchedule.findIndex(
          (el) => el._id === payload.payload._id
        );
        state.doctorSchedule[findIndex] = payload.payload;
      })
      .addCase(addDoctorSchedule.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(updateDoctorSchedule.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDoctorSchedule.fulfilled, (state, { payload }) => {
        state.loading = false;
        const findIndex = state.doctorSchedule.findIndex(
          (el) => el._id === payload.payload._id
        );
        state.doctorSchedule[findIndex] = payload.payload;
      })
      .addCase(updateDoctorSchedule.rejected, (state, action) => {
        state.loading = false;
      });

    /* -------------------------------------------------------------------- */
    /* ---------------------------- DOCTOR SCHEDULE ----------------------- */
    /* -------------------------------------------------------------------- */

    /* -------------------------------------------------------------------- */
    /* ---------------------------- CALENDER ------------------------------ */
    /* -------------------------------------------------------------------- */

    builder
      .addCase(fetchCalenderDuration.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCalenderDuration.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.calender = payload.payload;
      })
      .addCase(fetchCalenderDuration.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(addCalenderDuration.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCalenderDuration.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.calender = payload.payload;
      })
      .addCase(addCalenderDuration.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(updateCalenderDuration.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCalenderDuration.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.calender = payload.payload;
      })
      .addCase(updateCalenderDuration.rejected, (state, action) => {
        state.loading = false;
      });

    /* -------------------------------------------------------------------- */
    /* ---------------------------- CALENDER ------------------------------ */
    /* -------------------------------------------------------------------- */
  },
});

export const { setBillItems, setPaymentAccounts } = settingSlice.actions;

export default settingSlice.reducer;
