import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  CardHeader,
} from "reactstrap";
import FileCard from "../../../Components/Common/FileCard";
import Divider from "../../../Components/Common/Divider";
import DeleteModal from "../../../Components/Common/DeleteModal";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  IPD,
  CLINICAL_NOTE,
  clinicalNoteFields,
} from "../../../Components/constants/patient";
import { connect, useDispatch } from "react-redux";
import {
  addClinicalNote,
  createEditChart,
  removeClinicalNoteFile,
  updateClinicalNote,
} from "../../../store/actions";
import PreviewFile from "../../../Components/Common/PreviewFile";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const UploadedFiles = ({ id, chartId, files, appointment }) => {
  const dispatch = useDispatch();

  //get file
  const [file, setFile] = useState({
    img: null,
    isOpen: false,
  });

  //delete file
  const [deleteFile, setDeleteFile] = useState({
    img: null,
    isOpen: false,
  });

  //delete file modal functions
  const deleteFilePermanently = () => {
    dispatch(
      removeClinicalNoteFile({
        id,
        chartId,
        fileId: deleteFile.img._id,
        appointment,
      })
    );
    setDeleteFile({ img: null, isOpen: false });
  };
  const onClose = () => {
    setDeleteFile({ img: null, isOpen: false });
  };

  //file card functions
  const getDeleteFile = (img) => {
    setDeleteFile({
      img: img,
      isOpen: true,
    });
  };
  const onPreview = (img) => {
    setFile({
      img,
      isOpen: true,
    });
  };

  return (
    <Row className="row-gap-3">
      <Col xs={12}>
        <div className="d-flex align-items-center gap-3">
          <h6 className="display-6 fs-5 text-nowrap">Uploaded Files</h6>
          <Divider />
        </div>
      </Col>
      {(files || []).map((file, id) => (
        <Col key={id} xs={12} md={4}>
          <FileCard
            file={file}
            showDeleteButton
            onDelete={getDeleteFile}
            onPreview={onPreview}
          />
        </Col>
      ))}
      <PreviewFile
        file={file.img}
        isOpen={file.isOpen}
        toggle={() => setFile({ img: null, isOpen: false })}
      />
      <DeleteModal
        onDeleteClick={deleteFilePermanently}
        onCloseClick={onClose}
        show={deleteFile.isOpen}
      />
    </Row>
  );
};

const ClinicalNote = ({
  author,
  patient,
  chartDate,
  editChartData,
  appointment,
  patientLatestOPDPrescription,
  populatePreviousAppointment = false,
  shouldPrintAfterSave = false,
  type,
  onSubmitClinicalForm,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const editClinicalNote = editChartData?.clinicalNote;
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      author: author._id,
      patient: patient._id,
      center: patient.center._id || patient.center,
      centerAddress: patient.center.title,
      appointment: appointment?._id,
      addmission: patient.addmission?._id,
      chart: CLINICAL_NOTE,
      complaints: editClinicalNote ? editClinicalNote.complaints : "",
      observations: editClinicalNote ? editClinicalNote.observations : "",
      diagnosis: editClinicalNote ? editClinicalNote.diagnosis : "",
      notes: editClinicalNote ? editClinicalNote.notes : "",
      type,
      date: chartDate,
      shouldPrintAfterSave,
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      // const {
      //   author,
      //   patient,
      //   center,
      //   centerAddress,
      //   addmission,
      //   chart,
      //   type,
      //   date,
      //   complaints,
      //   observations,
      //   diagnosis,
      //   notes,
      // } = values;
      // const formData = new FormData();
      // formData.append("author", author);
      // formData.append("patient", patient);
      // formData.append("center", center);
      // formData.append("centerAddress", centerAddress);
      // formData.append("addmission", addmission);
      // formData.append("chart", chart);
      // formData.append("type", type);
      // formData.append("date", date);
      // formData.append("complaints", complaints);
      // formData.append("observations", observations);
      // formData.append("diagnosis", diagnosis);
      // formData.append("notes", notes);
      // files.forEach((file) => formData.append("file", file.file));

      // if (editClinicalNote) {
      //   formData.append("id", editChartData._id);
      //   formData.append("chartId", editClinicalNote._id);
      //   dispatch(updateClinicalNote(formData));
      // } else {
      //   dispatch(addClinicalNote(formData));
      // }
      onSubmitClinicalForm(values, files, editChartData, editClinicalNote);
      // closeForm();
    },
  });

  useEffect(() => {
    if (!editClinicalNote) {
      validation.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, editClinicalNote]);

  const closeForm = () => {
    validation.resetForm();
    dispatch(createEditChart({ data: null, chart: null, isOpen: false }));
  };

  const clinicalFiles = useMemo(() => {
    return (
      editClinicalNote?.files?.length > 0 && (
        <UploadedFiles
          id={editChartData._id}
          chartId={editClinicalNote._id}
          files={editClinicalNote.files}
          appointment={appointment?._id}
        />
      )
    );
  }, [editChartData, editClinicalNote, appointment]);

  const dropFiles = useMemo(() => {
    return (
      <CardBody>
        <FilePond
          files={files}
          onupdatefiles={setFiles}
          allowMultiple={true}
          maxFiles={10}
          name="files"
          acceptedFileTypes={["image/*", "application/pdf"]}
          className="filepond filepond-input-multiple"
          labelFileTypeNotAllowed={true}
        />
      </CardBody>
    );
  }, [files]);

  return (
    <React.Fragment>
      <div>
        {" "}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            // toggle();
            return false;
          }}
          className="needs-validation"
          action="#"
          encType="multipart/form-data"
        >
          <Row className="mt-3">
            {(clinicalNoteFields || []).map((item, idx) => (
              <Col xs={12} md={6}>
                <Card className="ribbon-box border shadow-none mb-3">
                  <CardBody className="position-relative p-0">
                    <div className="ribbon ribbon-primary w-75 ribbon-shape">
                      {item.label}
                    </div>
                    <Input
                      type="textarea"
                      name={item.name}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values[item.name] || ""}
                      //   onClick={() => handleDropdown("")}
                      className="form-control presc-border pt-5 rounded"
                      aria-label="With textarea"
                      rows="2"
                    />
                  </CardBody>
                </Card>
              </Col>
            ))}
            <Col xs={12} className="mt-3 mb-4">
              {clinicalFiles}
            </Col>
            <Col>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Multiple File Upload</h4>
                </CardHeader>
                {dropFiles}
              </Card>
            </Col>
            <Col xs={12} className="mt-3">
              <div className="d-flex gap-3 justify-content-end">
                <Button
                  onClick={closeForm}
                  size="sm"
                  color="danger"
                  type="button"
                >
                  Cancel
                </Button>
                <Button type="submit">
                  Save
                  {/* {chart ? "Update" : "Save"} */}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

ClinicalNote.propTypes = {
  patient: PropTypes.object,
  author: PropTypes.object,
  chartDate: PropTypes.any,
  editChartData: PropTypes.object,
  type: PropTypes.string.isRequired,
  onSubmitClinicalForm: PropTypes.func,
};

const mapStateToProps = (state) => ({
  patient: state.Chart.chartForm?.patient,
  author: state.User.user,
  chartDate: state.Chart.chartDate,
  editChartData: state.Chart.chartForm?.data,
  populatePreviousAppointment:
    state.Chart.chartForm.populatePreviousAppointment,
  shouldPrintAfterSave: state.Chart.chartForm.shouldPrintAfterSave,
  appointment: state.Chart.chartForm.appointment,
  patientLatestOPDPrescription: state.Chart.patientLatestOPDPrescription,
});

export default connect(mapStateToProps)(ClinicalNote);
