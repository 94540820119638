import { combineReducers } from "redux";

// Front
// import Layout from "./layouts/reducer";

// // Authentication
// import Login from "./auth/login/reducer";
// import Account from "./auth/register/reducer";
// import ForgetPassword from "./auth/forgetpwd/reducer";
// import Profile from "./auth/profile/reducer";
import User from "./features/auth/user/userSlice";

// alert
import Alert from "./features/alert/alertSlice";

// notification
import Notification from "./features/notification/notificationSlice";

// layout
import Layout from "./features/layouts/layoutsSlice";

// log
import Log from "./features/log/logSlice";

// center
import Center from "./features/center/centerSlice";

// recyclebin
import Recyclebin from "./features/recyclebin/recyclebinSlice";

// lead
import Lead from "./features/lead/leadSlice";

// booking
import Booking from "./features/booking/bookingSlice";

// patient
import Patient from "./features/patient/patientSlice";

// timeline
import Timeline from "./features/timeline/timelineSlice";

// chart
import Chart from "./features/chart/chartSlice";

// bill
import Bill from "./features/bill/billSlice";

// print
import Print from "./features/print/printSlice";

// medicine
import Medicine from "./features/medicine/medicineSlice";

// setting
import Setting from "./features/setting/settingSlice";

// Report
import Report from "./features/report/reportSlice";

const rootReducer = combineReducers({
  // ** public **
  User,
  Alert,
  Log,
  Center,
  Notification,
  Recyclebin,
  Setting,
  Lead,
  Booking,
  Medicine,
  // layout
  Layout,
  // patient
  Patient,
  Timeline,
  Chart,
  Bill,
  Print,
  Report,
});

export default rootReducer;
