import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Input,
  UncontrolledDropdown,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import debounce from "lodash.debounce"; // Use debounce utility
import config from "../../../config";

// Initialize the socket connection
const socket = io(config.api.BASE_URL, {
  path: "/socket/search",
});

const Medicine = ({ data, dataList, fieldName, addItem }) => {
  const [dropdown, setDropdown] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [filteredMedicines, setFilteredMedicines] = useState([]);

  // const handleChange = (e) => {
  //   const value = e.target.value;
  //   setSearchItem(value);
  // };

  const handleChange = (e) => {
    const val = e.target.value;
    setIsSearching(true);
    socket.emit("searchMedicine", val);
  };

  const debouncedOnChange = debounce(handleChange, 500);

  useEffect(() => {
    socket.on("medicineSearchResults", (data) => {
      setIsSearching(false);
      setFilteredMedicines(data);
    });
    // }

    // Cleanup on component unmount
    return () => {
      socket.off("medicineSearchResults");
    };
  }, []);

  return (
    <React.Fragment>
      <div>
        <Dropdown
          isOpen={dropdown}
          toggle={() => setDropdown(!dropdown)}
          className="me-2 dropdown-menu-md"
          direction="down"
        >
          <DropdownToggle className="p-0 w-100 position-relative" color="light">
            <Input
              value={searchItem}
              onChange={(e) => {
                setSearchItem(e.target.value);
                debouncedOnChange(e);
              }}
              size={"sm"}
              className="w-100 text-uppercase"
            />
            {/* add custom medicine */}
            <span
              onClick={() => {
                addItem(searchItem, data);
                setSearchItem("");
              }}
              className="link-success ri-send-plane-2-fill dropdown-input-icon"
            ></span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-md overflow-auto dropdown-height-md">
            <DropdownItem></DropdownItem>
            {(filteredMedicines || []).map((item) => (
              <DropdownItem
                className={
                  item.quantity === 0
                    ? "d-flex align-items-center fs-6 text-danger"
                    : "d-flex align-items-center link-primary fs-6"
                }
                key={item["_id"]}
                onClick={() => {
                  if (item.quantity === 0) {
                    toast.error("Medicine out of stock", {
                      position: "top-center",
                      autoClose: true,
                    });
                  } else addItem(item, data);
                }}
              >
                {/* {item["type"] === CAPSULE ? (
                    <i className="las la-pills text-info me-2 fs-5"></i>
                  ) : item["type"] === DROP ? (
                    <i className="bx bxs-droplet-half text-info me-2 fs-5"></i>
                  ) : item["type"] === CREAM ? (
                    <i className="las la-prescription-bottle-alt text-info me-2 fs-5"></i>
                  ) : null} */}
                <span>{item.type}</span>
                <span className="ms-2 text-capitalize">{item[fieldName]}</span>
                <span className="ms-2">
                  {item.strength} {item.unit}
                </span>
                {/* <span className="ms-auto">{item.quantity}</span> */}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

Medicine.propTypes = {
  data: PropTypes.array,
  setMedicines: PropTypes.func,
  dataList: PropTypes.array,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Medicine);
